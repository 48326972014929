import { Affix, Badge, Button, Card, Checkbox, Col, Divider, Form, Input, message, Modal, Radio, Row, Space, Spin, Table, Tabs, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";
import PromoCodes from "../components/components/checkout/PromoCodes";
import ShippingProvider from "../components/components/newcheckout/ShippingProvider";
import { apiCall } from "../components/utilities/Api";
import conditional from "../components/utilities/conditional";
import Html from "../components/utilities/Html"
import { useSession } from "../util/Session";
import { Link, useHistory } from "react-router-dom";
import PhoneNumberFormatter from "../components/formatters/PhoneNumberFormatter";
import CustomForm from "../components/components/checkout/CustomForm";
import GiftCardPurchase from "../components/components/giftcards/GiftCardPurchase";
import { getPaymentButton } from "../components/components/checkout/PaymentChoices";
import ShippingForm from "../components/components/checkout/ShippingForm";
import { getItemAlt, pluralize } from "../util/Utils";
import { PayPalButton } from "react-paypal-button-v2";
import { pixelTrack } from "../util/SiteRouter";
import CreditCardWrapper from "../components/components/checkout/CreditCardWrapper";
import ClearentPaymentsForm from "../components/components/checkout/ClearentPaymentsForm";
import GlobalWrapper from "../components/components/checkout/GlobalWrapper";
import { useMediaQuery } from "react-responsive";
export default function PurchaseLayout() {

    const ismobile = useMediaQuery({ query: '(max-width: 716px)' });

    const history = useHistory();

    // No delivery method - send to cart
    if (!history?.location?.state?.delivery_method) {
        history.push("/checkout/cart")
    }


    const [session, setSession] = useSession();
    const [form] = Form.useForm();
    const [isValid, setIsValid] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [choice, setChoice] = useState("");
    const [paymentOptions, setPaymentOptions] = useState({ "ship": [], "pickup": [], "electronic": [] })
    const [loadingShipping, setLoadingShipping] = useState(true)
    const [loadingPayments, setLoadingPayments] = useState(true);
    const [locationNotes, setLocationNotes] = useState("");
    const [shippingProviders, setShippingProviders] = useState([]);

    const [state, setState] = useState({
        delivery_method: history?.location?.state?.delivery_method || "",
        custom: history?.location.state?.custom || false,
        address_id: null,
        payment_method: false,
        gift_registry_id: null,
    })

    let can_submit = true;
    const submit = (_form) => {
        // Disable multiple submits
        if (!can_submit) {
            setTimeout(() => {
                can_submit = true;
            }, 1000)
            return;
        }
        can_submit = false;
        setSubmitLoading(true);

        // Add custom form elements
        let custom_fields = Object.fromEntries(Object.entries(_form).filter(([key]) => key.startsWith("cfe")));

        let obj = {
            ...custom_fields,
            custom: state.custom,
            delivery_preference: (_form["shipping_provider"] === "local_delivery") ? "local_delivery" : state.delivery_method,
            preferred_communication: _form["preferred_communication"],
            gift_cards: session.cart.hasOwnProperty("gift_cards") ? JSON.stringify(session.cart.gift_cards) : "",
            instructions: _form["instructions"] || "",
            old_promo_code: _form["promo_code"] || "",
            payment_choice: state.payment_method
        };

        // Check for gift registry
        if (state.gift_registry_id) {
            obj.gift_registry_id = state.gift_registry_id;
        }

        // Check & Use Credit Card 
        if (state.payment_method === "credit_card") {
            obj.encrypted_cc_info = _form["encrypted_cc_info"];
        }

        // Shipping Specific
        if (state.delivery_method === "ship" || state.delivery_method === "local_delivery") {
            obj.address_id = state.address_id;
            obj.provider = _form["shipping_provider"];
            obj.multiship_method = _form["multiship_method"];
        }

        // Extra fields if not logged in
        if (!session.logged_in) {
            obj.name = _form["name"];
            obj.email = _form["email"];
            obj.phone = _form["phone"];
        }

        // Add promo code if provided
        const promo = session.cart.summary.promo_codes.find(item => !item.automatic);
        if (promo) {
            obj.promo_code = promo.code
        };

        // Clear unused keys
        Object.keys(obj).forEach(key => {
            if (obj[key] === "") delete obj[key];
        });

        // Clearent & Global
        if (state.payment_method === "clearent" || state.payment_method === "global") {
            obj.transaction_data = form.getFieldValue("transaction_data");
            apiCall("checkout/cardPayment", obj, (_status, _result) => {
                if (_status) {
                    let order_number = _result.order_number;
                    history.push({ "pathname": "/account/order/" + order_number, "state": { "data": _result }, "search": "success" });
                } else {
                    Modal.error({ title: "There was an error", content: <>{_result.error}</> })
                }
            })
            setSubmitLoading(false);
            can_submit = true;
            return;
        }

        // Paypal
        if (state.payment_method === "paypal") {
            obj.order_id = form.getFieldValue("paypal_order_id");
            apiCall("checkout/paypalSuccess", obj, (_status, _result) => {
                if (_status) {
                    let order_number = _result.order_number;
                    history.push({ "pathname": "/account/order/" + order_number, "state": { "data": _result }, "search": "success" });
                } else {
                    Modal.error({
                        title: 'There was an error processing your order.',
                        content: <>No funds were removed from your PayPal account - Please ensure you start and complete your checkout in a single browser tab. </>,
                        okText: "Start Over",
                        onOk: () => history.push("/checkout/cart")
                    });


                }
                setSubmitLoading(false);
                can_submit = true;
            })
            return;
        }

        // Regular Checkout
        apiCall("checkout/submit", obj, (_status, _result) => {
            if (_status) {
                pixelTrack("Purchase", session.cart, obj);
                let order_number = _result.order_number;
                history.push({ "pathname": "/account/order/" + order_number, "state": { "data": _result }, "search": "success" });
            } else {
                if (typeof _result.error === 'string') {
                    setErrorMSG(_result.error);
                    message.error(<span aria-live="assertive">{_result.error}</span>)
                } else {
                    let _str = JSON.stringify(_result.error);
                    setErrorMSG("There was an error: (" + _str + ")");
                    message.error(<span aria-live="assertive">There was an error: {_str}</span>)
                }
            }
            setSubmitLoading(false);
            can_submit = true;
        });

    }

    const sumTaxes = (_arr) => {
        let ret = 0;
        _arr.forEach(_itm => {
            ret += parseFloat(_itm.amount);
        });
        return ret.toFixed(2);
    }




    const displayPaypalButton = () => {

        return (
            <>
                <PayPalButton
                    createOrder={(data, actions) => {
                        // DISCOUNT LOGIC
                        let total_discount = 0;
                        let total_item_total = parseFloat(session.cart.summary.subtotal);
                        if (session.cart.summary.promo_codes.length > 0) {
                            // Add Promo Discount To Total Discount
                            total_discount = parseFloat(session.cart.summary.promo_codes[0].amount);
                            // Add Promo Discount To Total Item
                            // DONT ADD TO THE TOTAL IF IT'S FREE SHIP
                            if (session.cart.summary.promo_codes[0].type !== "freeship") {
                                total_item_total = total_item_total + parseFloat(session.cart.summary.promo_codes[0].amount);
                            }
                        }
                        // Add Giftcard discount to Total Discount
                        total_discount = total_discount + parseFloat(session.cart.summary.post_subtotal_discount);
                        // Convert back to strings
                        total_discount = total_discount.toFixed(2).toString();
                        total_item_total = total_item_total.toFixed(2).toString();
                        return actions.order.create({
                            application_context: {
                                shipping_preference: "NO_SHIPPING" // default is "GET_FROM_FILE"
                            },
                            purchase_units: [{
                                amount: {
                                    currency_code: window.sitesettings.checkout_info.currency,
                                    value: session.cart.summary.total,
                                    breakdown: {
                                        item_total: { value: total_item_total, currency_code: window.sitesettings.checkout_info.currency },
                                        tax_total: { value: sumTaxes(session.cart.summary.taxes), currency_code: window.sitesettings.checkout_info.currency },
                                        shipping: { value: session.cart.summary.shipping, currency_code: window.sitesettings.checkout_info.currency },
                                        discount: { value: total_discount, currency_code: window.sitesettings.checkout_info.currency }
                                    }
                                },
                                items: session.cart.items.map(item => {
                                    return {
                                        name: item.title_info.title,
                                        // unit_amount: { value: item.title_info.available.row_price, currency_code: window.sitesettings.checkout_info.currency },
                                        unit_amount: { value: item.cart_info.sold_price, currency_code: window.sitesettings.checkout_info.currency },
                                        quantity: item.cart_info.quantity,
                                    }
                                })
                            }],
                        });
                    }}

                    amount={session.cart.summary.total}
                    shippingPreference="NO_SHIPPING"

                    style={{
                        shape: 'rect',
                        color: 'gold',
                        layout: 'horizontal',
                        label: 'paypal',
                    }}

                    onApprove={(data, actions) => {
                        form.setFieldsValue({ "paypal_order_id": data.orderID });
                        return form.submit(data.orderID);
                    }}

                    options={{
                        currency: window.sitesettings.checkout_info.currency,
                        clientId: window.sitesettings.checkout_info.paypal_id
                    }}

                />
            </>
        )
    }

    const scrollToElement = (_element, offset = affixed + 40) => {
        const element = document.getElementById(_element);
        if (element) {
            const elementPosition = element.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({ top: elementPosition - offset, behavior: "smooth" });
        }
    };


    // Validate Form
    const validateForm = async (_trigger = false) => {
        if (state.payment_method || _trigger) {
            try {
                await form.validateFields();
                setIsValid(true);
                if (_trigger) {
                    if (ismobile) {
                        scrollToElement("end", affixed);
                    }
                }
            } catch (error) {
                if (error?.outOfDate && error?.errorFields?.length === 0) {
                    setIsValid(true)

                    if (_trigger) { scrollToElement("end", affixed); }

                } else {
                    setIsValid(false);
                    if (_trigger) {
                        scrollToElement(error.errorFields[0]["name"]);
                        message.error(<span role="alert" aria-live="assertive" >Missing required field. Please check your input and try again.</span>)
                    }
                }
            }
        }
    };


    useEffect(() => {

        // Send back to cart page 
        if (session.cart.contains_unavailable) {
            history.push("/checkout/cart");
        }

        if (session.cart.contains_unshippable && state.delivery_method === "ship") {
            history.push("/checkout/cart");
        }

        if (session.cart.summary.contains_only_emailed_gift_card && state.delivery_method !== "electronic") {
            history.push("/checkout/cart")
        }

        if (!session.cart.summary.contains_only_emailed_gift_card && state.delivery_method === "electronic") {
            history.push("/checkout/cart")
        }
    }, [session.cart.summary.contains_only_emailed_gift_card, session.cart.contains_unshippable, session.cart.contains_unavailable])



    useEffect(() => {
        setState({ ...state, "payment_method": "" });
        form.setFieldsValue({ "payment_method": "" });
        setChoice("");
        validateForm();

    }, [session?.cart?.summary?.subtotal, state.address_id]);

    const show_terms = true;
    const title_items = session.cart.summary.item_count + " " + pluralize(session.cart.summary.item_count, "title", "s") + ", " + session.cart.summary.total_quantity + " " + pluralize(session.cart.summary.total_quantity, "item", "s");

    // Header Height
    const { data = {} } = window.sitesettings.global_content.find(item => item.component === "search_bar") || {};
    const { logo_height = 40, padding_y = 2 } = data;
    const affixed = (logo_height + padding_y + padding_y + 20);

    const enabled_prefs = Object.keys(window.sitesettings.store_info.allowed_comm_prefs).filter(key => (window.sitesettings.store_info.allowed_comm_prefs[key]));
    const instructions_message = (window.sitesettings.checkout_info.show_order_inscription) ? "Inscription" : "Instructions or comments"

    // Form Changes
    useEffect(() => {
        const _address_id = form.getFieldValue("address_id");
        if (_address_id || _address_id === "" && _address_id !== state?.address_id) {
            setState({ ...state, "address_id": _address_id })
            getShippingProviders(_address_id);
        }

    }, [form.getFieldValue("address_id"), session.cart.summary.subtotal]);


    const loadPaypalSDK = () => {
        if (window.sitesettings.checkout_info.paypal_id) {
            const script = document.createElement("script");
            script.src = "https://www.paypal.com/sdk/js?client-id=" + window.sitesettings.checkout_info.paypal_id + "&currency=" + window.sitesettings.checkout_info.currency;
            script.async = true;
            document.body.appendChild(script);
        }
    }
    useEffect(loadPaypalSDK, []);


    const getPaymentChoices = () => {
        setLoadingPayments(true);
        setPaymentOptions({
            "ship": [],
            "pickup": [],
            "electronic": []
        });
        apiCall("checkout/getPaymentChoices", {}, (_status, _result) => {
            window.payment = ""
            if (_status) {
                setPaymentOptions(_result);
            }
            setLoadingPayments(false);
            validateForm();
        })
    }

    useEffect(getPaymentChoices, [session?.cart?.summary?.subtotal, session?.cart?.summary?.total_gift_card_amount_used])


    const selectDefault = (_result) => {


        let set = _result.filter(item => item.available && item.delivery_preference !== "local_delivery");


        if (set.length > 0) {
            set.sort((a, b) => (parseFloat(a.cost) > parseFloat(b.cost)) ? 1 : -1)
            let _select = set[0];
            let _hash = hashCode(_select.provider + _select.delivery_preference)
            selectProvider(_hash, _select.provider, _select.delivery_preference, "all");
        } else {

            let find = _result.find(item => item.available && item.delivery_preference === "local_delivery");
            if (find) {
                let _hash = hashCode(find.provider + find.delivery_preference)
                selectProvider(_hash, find.provider, find.delivery_preference, "all");
            }
        }
    }


    const getShippingProviders = (_address_id) => {
        setLoadingShipping(true);
        setShippingProviders([])
        apiCall("checkout/getShippingProviders", { address_id: _address_id }, (_status, _result) => {
            if (_status) {
                setShippingProviders(_result)
                selectDefault(_result)
            } else {
                form.setFieldsValue({ "shipping_provider": "" });
                form.setFieldsValue({ "shipping_label": "" });
            }
            setLoadingShipping(false);
            validateForm();
        });
    }

    const hashCode = (_string) => {
        let hash = 0;
        for (let i = 0; i < _string.length; i++) {
            let char = _string.charCodeAt(i);
            hash = ((hash << 5) - hash) + char;
            hash = hash & hash;
        }
        return hash
    }

    const columns = [
        {
            key: "image", title: "Image", width: 45, dataIndex: "name", render: (e, f) => {
                const ti = f.title_info;
                return (<img onError={(i) => {
                    i.target.src = "https://cdn1.bookmanager.com/i/nocover.png";
                    i.target.className = "light-cart";
                }}
                    alt={getItemAlt(ti)}
                    className="shadow-cart"
                    style={{ "maxHeight": "50px", "maxWidth": "35px" }}
                    src={"https://cdn1.bookmanager.com/i/m?b=" + ti.eisbn + "&cb=" + ti.cover_image_cache}
                />)
            }
        },
        {
            key: "title", title: "Title", dataIndex: "title", render: (e, f) => {
                const ti = f.title_info;
                if (f.cart_info?.cart_notes?.gift_card_info?.recipient_email) {
                    // Draw giftcard
                    const gc = f.cart_info.cart_notes.gift_card_info;
                    return (
                        <>
                            <div style={{ "width": "100px", "overflow": "hidden", "textOverflow": "ellipsis" }}>{ti.title} <span>{ti.subtitle}</span></div>
                            <Space wrap>
                                <div><small><div style={{ "opacity": "0.5", "display": "inline-block" }}>From: &nbsp;</div><em>{gc.from}</em></small></div>
                                <div><small><div style={{ "opacity": "0.5", "display": "inline-block" }}>To: &nbsp;</div><em>{gc.to} - {gc.recipient_email}</em></small></div>
                            </Space>
                            <div><small><em>"{gc.message}"</em></small></div>
                        </>
                    )
                }
                return (<div>
                    <Tooltip title={<><div>{ti.title}</div><div><small>{ti.subtitle}</small></div></>}>
                        <div style={{ "width": "100%", "overflow": "hidden", "textOverflow": "ellipsis", "whiteSpace": "nowrap" }}>
                            <span>{ti.title}</span>
                            <span><small style={{ "opacity": "0.85" }}> {ti.subtitle}</small>
                            </span>
                        </div>
                    </Tooltip>
                    <div><small>{f?.title_info?.binding || ""}</small></div>
                </div>)
            }
        },
        {
            key: "price", width: 100, title: "price", dataIndex: "price", render: (e, f) => {
                const { giftcard_price = false } = f.cart_info.cart_notes;
                return (<>
                    <div>{f?.cart_info?.quantity} <small>x</small> ${(giftcard_price) ? giftcard_price : f.title_info.available.row_price}</div>
                    <conditional.true value={(f.title_info.available.discount)}>
                        <small><em style={{ "color": "#e70d3d" }}>{f.title_info.available.discount}%&nbsp;off&nbsp;${f.title_info.available.price}</em></small>
                    </conditional.true>
                </>)
            }
        }
    ];


    const AcceptTerms = (props) => {

        const { show_terms } = props;
        const [showWarning, setShowWarning] = useState(window.sitesettings.checkout_info.terms_consent_enabled)

        if (window.sitesettings.checkout_info.terms_consent_enabled && show_terms) {
            return (
                <>
                    <div style={{ "marginTop": "15px" }}><Checkbox onChange={(e) => setShowWarning(!e.target.checked)}><p style={{ "fontSize": "13px" }}><nobr>I accept the <a href="/terms-conditions" target="_blank" aria-label="Terms & Conditions (opens in a new tab)" title="Terms & Conditions" style={{ "color": "#069" }}>Terms & Conditions</a>.</nobr></p></Checkbox></div>
                    <div style={{ "marginTop": "-15px" }}>
                        <Spin style={{ "width": "250px" }} spinning={showWarning} indicator={<></>}>
                            {props.children}
                        </Spin>
                    </div>
                </>
            )
        } else {
            return props.children;
        }

    }

    const drawPromoLineItems = (_arr, show_ship = false) => {
        if (!_arr) {
            return (<></>);
        }
        return _arr.map((item, index) => {
            if (((show_ship) && item.type !== "freeship") || (!show_ship) && item.type === "freeship") {
                return (<></>);
            }
            return (
                <Row>
                    <Col span={17}><span style={{ "color": "#e70d3d" }}><small>{item.description}</small></span></Col>
                    <Col span={7}><span style={{ "color": "#e70d3d", "textAlign": "right", "display": "block" }}><small>- ${item.amount}</small></span></Col>
                </Row>
            )
        })
    }

    const drawTaxes = (_arr) => {
        return _arr.map((item, index) => {
            return (
                <Row key={item}>
                    <Col span={17}><small>{(item.type) ? <>{item.type} </> : ""}</small></Col>
                    <Col span={7}><span style={{ "textAlign": "right", "display": "block" }}><small>{"$" + item.amount}</small></span></Col>
                </Row>
            )
        })
    }


    const drawCart = () => {
        return (<Table className="cart-table" size="small" pagination={{ pageSize: 3, style: { "marginBottom": "0px" } }} showHeader={false} scroll={{ y: 210 }} style={{ "borderBottom": "0px" }} columns={columns} dataSource={session?.cart?.items || []} />)
    }


    const drawSubmit = () => {


        if (!isValid) {
            return (<Button disabled={!isValid} type="primary" size="large" block >{(isValid) ? "Valid" : "Complete form & choose payment"}</Button>)
        }

        switch (state.payment_method) {

            case "paypal":
                return displayPaypalButton();
                break;

            case "clearent":
                return <div><ClearentPaymentsForm onComplete={(transaction_data) => {
                    form.setFieldsValue({ "transaction_data": transaction_data });
                    form.submit()
                }} amount={session.cart.summary.total} /></div>

            case "global":
                return <div>
                    <GlobalWrapper onComplete={(transaction_data) => {
                        form.setFieldsValue({ "transaction_data": transaction_data });
                        form.submit()
                    }} amount={session.cart.summary.total}><Button type="primary" size="large" block >Enter CreditCard</Button>
                    </GlobalWrapper>
                </div>

            case "pickup":
                return (<Button type="primary" onClick={() => form.submit()} size="large" block >Finalize Checkout</Button>);
            case "contact":
                return (<Button type="primary" onClick={() => form.submit()} size="large" block >Finalize Checkout</Button>);
            case "no_global":
                return (<Button type="primary" onClick={() => form.submit()} size="large" block >Finalize Checkout</Button>);
            case "not_required":
                return (<Button type="primary" onClick={() => form.submit()} size="large" block >Finalize Checkout</Button>);
            case "electronic":
                return (<Button type="primary" onClick={() => form.submit()} size="large" block >Finalize Checkout</Button>);


            case "credit_card":
                return (
                    <CreditCardWrapper onComplete={(_cc_info) => {
                        form.setFieldsValue({ "encrypted_cc_info": _cc_info });
                        form.submit();
                    }}>
                        <Button type="primary" size="large" block >Enter CreditCard</Button>
                    </CreditCardWrapper>
                )
        }

    }


    const drawSummary = () => {

        return (
            <div style={{ "position": "sticky", "top": affixed + "px", "padding": "20px" }}>
                <div className="hide"><PromoCodes /></div>
                <div className="shim" />
                <Card bordered={false} headStyle={{ "border": '1px solid #dedede', "borderRadius": "0px" }} className="adjust-margin" bodyStyle={{ "paddingTop": "0px", "border": "1px solid #dedede" }} title={(<div>
                    <div className="float-flex" style={{ "alignItems": "center" }}>
                        <strong style={{ "fontSize": "18px" }}>Order Summary</strong>
                        <div style={{ "display": "block", "fontSize": "12px" }}>{title_items}</div>
                    </div>

                </div>)}>

                    <Tabs>
                        <Tabs.TabPane tab="Summary" key="1">

                            <conditional.true value={(session.cart.summary.hasOwnProperty('original_price'))}>
                                <Row>
                                    <Col span={17}>Price</Col>
                                    <Col span={7}><span style={{ "textAlign": "right", "display": "block" }}>{"$" + session.cart.summary.original_price}</span></Col>
                                </Row>
                            </conditional.true>

                            <conditional.true value={(session.cart.summary.discounts) && (session.cart.summary.discounts !== "0.00")}>
                                <Row>
                                    <Col span={17}><span style={{ "color": "#e70d3d" }}><small>Discount</small></span></Col>
                                    <Col span={7}><span style={{ "textAlign": "right", "display": "block", "color": "#e70d3d" }}><small>{" - $" + session.cart.summary.discounts}</small></span></Col>
                                </Row>
                            </conditional.true>

                            <conditional.true value={(session.cart.summary.hasOwnProperty('promo_codes') && session.cart.summary.promo_codes.length > 0)}>
                                {drawPromoLineItems(session.cart.summary.promo_codes)}
                            </conditional.true>

                            <Row>
                                <div aria-hidden><Divider style={{ "margin": "10px 0px" }} /></div>
                                <Col span={17}>Subtotal</Col>
                                <Col span={7}><span style={{ "textAlign": "right", "display": "block" }}>{"$" + session.cart.summary.subtotal}</span></Col>
                            </Row>

                            {drawTaxes(session.cart.summary.taxes)}

                            <conditional.true value={(session.cart.summary.shipping !== "0.00")}>
                                <Row>
                                    <Col span={17}><small>Shipping</small></Col>
                                    <Col span={7}><span style={{ "textAlign": "right", "display": "block" }}><small>{"$" + session.cart.summary.shipping}</small></span></Col>
                                </Row>
                            </conditional.true>

                            <conditional.true value={(session.cart.summary.hasOwnProperty('promo_codes') && session.cart.summary.promo_codes.length > 0)}>
                                {drawPromoLineItems(session.cart.summary.promo_codes, true)}
                            </conditional.true>

                            <conditional.true value={(session.cart.summary.hasOwnProperty("total_gift_card_amount_used") && (session.cart.summary.total_gift_card_amount_used !== "0.00"))}>
                                <Row>
                                    <Col span={17}><span style={{ "color": "#e70d3d" }}>Gift Cards</span></Col>
                                    <Col span={7}><span style={{ "textAlign": "right", "display": "block", "color": "#e70d3d" }}>{"-$" + session.cart.summary.total_gift_card_amount_used}</span></Col>
                                </Row>
                            </conditional.true>

                            <div aria-hidden><Divider style={{ "margin": "10px 0px", "marginBottom": "20px" }} /></div>
                            <Row>
                                <Col span={17}><strong style={{ "fontSize": "18px", "color": "#202020" }}>Total</strong></Col>
                                <Col span={7}><strong><span style={{ "textAlign": "right", "display": "block", "fontSize": "16px", "color": "#202020" }}>{"$" + session.cart.summary.total}</span></strong></Col>
                            </Row>



                            <AcceptTerms show_terms={show_terms}>
                                <Row>
                                    <Col span={24}>
                                        <span style={{ "textAlign": "right", "display": "block" }}>
                                            {/* {props.children} */}
                                        </span>
                                    </Col>
                                </Row>
                            </AcceptTerms>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Cart" key="2">
                            {drawCart()}
                        </Tabs.TabPane>

                    </Tabs>

                </Card>
                <div className="shim" /><div className="shim" />
                {drawSubmit()}
            </div>
        )

    }



    const drawBillingShipping = () => {

        return (
            <div id="shipping">
                <ShippingForm state={state}
                    callback={(e) => {
                        setSession({ ...session, "user": { ...session.user, "addresses": e } })
                        getShippingProviders(state.address_id);
                    }}

                    setState={(e) => {
                  
                        setState(e);
                        getShippingProviders(e.address_id);


                    }} hideCard={true} setLoading={() => { }} setShipping={(e) => { }} errorMSG={""} setErrorMSG={() => { }} form={form} delivery={state.delivery_method} setSaved={(e) => { }} saved={{}} />
            </div>
        )

    }


    const [selected, setSelected] = useState(0);
    const [errorMSG, setErrorMSG] = useState("");

    const selectProvider = (_hash, _provider, _delivery_preference, _batch = "all") => {


        //if (!state.address_id && state.address_id !=="") { return; }

      let _address_id = form.getFieldValue("address_id");
        apiCall("checkout/setShipping", {
            address_id: _address_id,
            delivery_preference: _delivery_preference,
            provider: _provider,
            multiship_method: _batch
        }, (_s, _r) => {
            if (_s) {
                setSession({ ...session, "cart": _r })
                setSelected(_hash);
                if (_delivery_preference === "local_delivery") {
                    form.setFieldsValue({ "shipping_provider": "local_delivery" });
                    form.setFieldsValue({ "shipping_label": "true" });
                    //setState({...state, "delivery_method" : "local_delivery"})
                } else {
                    form.setFieldsValue({ "shipping_provider": _provider });
                    form.setFieldsValue({ "shipping_label": "true" });
                    //setState({...state, "delivery_method" : "ship"})
                }

                form.setFieldsValue({ "multiship_method": _batch });
            }
        })

    }

    const drawShipping = () => {

        if (loadingShipping) {
            return (<></>)
        }


        if (shippingProviders.length === 0 || state.address_id === null) {
            return (<Card style={{ "borderColor": "#f12a46" }}><span style={{ "color": "#f12a46" }}>No shipping available for the selected address</span></Card>)
        }

        return shippingProviders.map(item => {
            return (
                <div key={hashCode(item.provider + item.delivery_preference)} >
                    <ShippingProvider autoselect={false} saved={{}} setSaved={() => { }} address_id={state.address_id} selectProvider={selectProvider} selected={selected} hash={hashCode(item.provider + item.delivery_preference)} data={item} />
                    <div className="shim" /><div className="shim" />
                </div>
            )
        })
    }


    const displayClickCollectForm = () => {
        if (session.cart.can_click_collect && !session.logged_in) {
            return (<>
                <Form.Item label="Your name" name="name" rules={[{ required: true, message: 'Please input your name.' }]}>
                    <Input size="large" placeholder="Your name" />
                </Form.Item>
                <Form.Item label="Email address" name="email" rules={[{ type: "email", required: true, message: 'Please input your email address.' }]}>
                    <Input size="large" placeholder="Email address" type="email" />
                </Form.Item>
                <Form.Item label="Phone" name="phone" rules={[{ required: true, message: 'Please input your phone number.' }]}>
                    {/* <Input placeholder="Phone" inputMode="numeric" pattern="[0-9]*" /> */}
                    <PhoneNumberFormatter form={form} country={"Canada"} style={{ width: "156px" }} inputMode="numeric" placeholder="Phone Number" />
                </Form.Item>
            </>)
        } else {
            return (<></>)
        }
    }

    const getCustomForm = () => {

        let ci = window.sitesettings.checkout_info;
        let formdata = false;
        switch (state.delivery_method) {
            case "pickup":
                if ((ci.form_pickup || ci.form_pickup === 0) && !state.custom) {
                    formdata = ci.custom_forms[ci.form_pickup]
                }
                if ((ci.form_custom_pickup || ci.form_custom_pickup === 0) && state.custom) {
                    formdata = ci.custom_forms[ci.form_custom_pickup]
                }
                break;
            case "ship":
            case "local_delivery":
                if ((ci.form_ship || ci.form_ship === 0) && !state.custom) {
                    formdata = ci.custom_forms[ci.form_ship]
                }
                if ((ci.form_custom_ship || ci.form_custom_ship === 0) && state.custom) {
                    formdata = ci.custom_forms[ci.form_custom_ship]
                }
                break;
        }

        if (formdata) {
            return formdata;
        } else {
            return false;
        }
    }

    const customForm = getCustomForm();

    const drawContact = () => {

        let communication_preference;
        if (session?.logged_in === true
            && session?.user?.communication_preference === "text"
            && window.sitesettings?.store_info?.allowed_comm_prefs?.text) {
            communication_preference = "text";
        } else if (session?.logged_in === true
            && session?.user?.communication_preference !== "text") {
            communication_preference = session?.user?.communication_preference;
        } else {
            communication_preference = "phone";
        }


        const allowedPrefs = window.sitesettings.store_info.allowed_comm_prefs;
        const availablePrefs = [
            allowedPrefs.email ? "email" : null,
            allowedPrefs.phone ? "phone" : null,
            (allowedPrefs.text && window.sitesettings.store_info.texting_enabled) ? "text" : null
        ].filter(Boolean); // Remove null values

        const defaultPreference = communication_preference || availablePrefs[0] || null;


        return (
            <Card>

                {displayClickCollectForm()}

                <Form.Item label="Purchase Order #" name="promo_code">
                    <Input size="large" maxLength={10} placeholder="Purchase Order # (optional)" />
                </Form.Item>

                <Form.Item label={instructions_message} name="instructions">
                    <Input.TextArea size="large" maxLength={1000} rows={5} placeholder={instructions_message} />
                </Form.Item>

                <Form.Item initialValue={defaultPreference} label="Preferred method of communication" name="preferred_communication">
                    <Radio.Group buttonStyle="solid">
                        {allowedPrefs.email && <Radio.Button value="email">Email</Radio.Button>}
                        {allowedPrefs.phone && <Radio.Button value="phone">Phone</Radio.Button>}
                        {allowedPrefs.text && window.sitesettings.store_info.texting_enabled && (
                            <Radio.Button value="text">Text</Radio.Button>
                        )}
                    </Radio.Group>
                </Form.Item>

                <CustomForm formdata={customForm} saved={{}} form={form} />
                {/* {JSON.stringify(saved, null, 2)} */}


            </Card>
        )
    }

    const drawEmailGiftCardsDisclaimer = () => {
        return (
            <div>
                <div><strong>{window.sitesettings.store_info.giftcard_emailed_label}</strong></div>
                <div aria-hidden><Divider dashed style={{ "margin": "15px 0px" }} /></div>
                <Html className="" html={window.sitesettings.store_info.giftcard_emailed_message} />
                <br />
            </div>
        )
    }

    const drawNotice = () => {


        if (state.delivery_method !== "electronic" && session.logged_in && (!window.sitesettings.checkout_info.checkout_message || window.sitesettings.checkout_info.checkout_message === "<br>") && !session.cart.summary.contains_emailed_gift_card) {
            return (<></>)
        }

        if (state.delivery_method === "electronic") {
            return (
                <div>
                    {/* <div aria-hidden><Divider orientation="left" ><big><strong>Notice</strong></big></Divider></div> */}
                    <div style={{ "padding": "30px", "border": "1px dashed #ddd" }}>
                        {drawEmailGiftCardsDisclaimer()}
                    </div>
                </div>
            )
        }

        return (
            <>
                {/* <div aria-hidden><Divider orientation="left" ><big><strong>Notice</strong></big></Divider></div> */}
                <div style={{ "padding": "30px", "border": "1px dashed #ddd" }}>
                    <conditional.true value={(!session.logged_in)}>
                        <conditional.true value={(window.sitesettings.checkout_info.ship_enabled)}>
                            <p style={{ "color": "#e70d3d" }}><em>Guest checkout is for in-store pick up only. If you would like your order shipped, <Link className="asa-link" to="/checkout/cart"><strong style={{ "color": "#e70d3d" }}>tap here to go back</strong></Link> and log in or create an account.</em></p>
                        </conditional.true>
                    </conditional.true>
                    <Html className="" html={window.sitesettings.checkout_info.checkout_message} />
                    <conditional.true value={(session.cart.summary.contains_emailed_gift_card)}>
                        {drawEmailGiftCardsDisclaimer()}
                    </conditional.true>
                </div>
            </>)
    }


   


    const displayChoices = () => {
        if (state.delivery_method) {
            let type = (state.delivery_method === "local_delivery" || state.delivery_method === "custom_ship") ? "ship" : state.delivery_method;
            type = (state.delivery_method === "custom_pickup") ? "pickup" : type;
            return paymentOptions[type].map((_item, _index) => {
                return (
                    <div style={{ "opacity": (session.cart.summary.total === "0.00" && _item !== "not_required") ? "0.5" : "1" }} key={_index}>
                        <span onClick={() => {
                            form.setFieldsValue({ "payment_method": _item });
                            setState({ ...state, "payment_method": _item });
                            setChoice(_item);
                            validateForm(true);
                        }}>{getPaymentButton(_item, _index, (choice === _item) ? "selected" : "default", (session.cart.summary.total === "0.00" && _item !== "not_required"))}</span>
                        <div className="shim"></div>
                    </div>)
            })
        }
    }


    const drawPayment = () => {

        if (loadingPayments) {
            return (<>loading...</>)
        }

        return (
            <div>
                <div aria-hidden><Divider orientation="left" ><big><strong>Select Payment</strong></big></Divider></div>
                <Form.Item hidden rules={[{ required: true }]} name="payment_method">
                    <Input />
                </Form.Item>

                <Form.Item hidden name="transaction_data">
                    <Input />
                </Form.Item>

                <Form.Item hidden name="encrypted_cc_info">
                    <Input />
                </Form.Item>

                <Form.Item hidden name="paypal_order_id">
                    <Input />
                </Form.Item>


                {/* <conditional.true value={(session.cart.summary.total === "0.00")}>
                    <span onClick={() => {
                           form.setFieldsValue({ "payment_method": "not_required" });
                            setState({ ...state, "payment_method": "not_required" });
                            setChoice("not_required");
                            validateForm(true);
                    }}>
                        <Button style={{ "height": "50px" }} block><strong style={{ "fontWeight": "bold", "color": "#1f1f1f" }} >&nbsp;  Continue (it's free!)  </strong    ></Button>
                    </span>
                </conditional.true> */}

                <div style={{ "opacity": (session.cart.summary.total === "0.00") ? "0.5" : "1" }} key={"giftcard"}>
                    <div className="shim"></div>
                </div>

                {displayChoices()}

                <conditional.true value={(session.cart.summary.is_shipping_tbd)}>
                    <Card>
                        <strong>Heads up!</strong>
                        <p>Shipping charges still need to be calculated for your order. We will contact you shortly to confirm the cost to ship before finalizing any additional payments.</p>
                    </Card>
                </conditional.true>
            </div>
        )
    }

    const drawMobileSummary = () => {

        return (

            <div className="show-block mobile-summary-wrap" style={{ "position": "sticky", "top": (affixed) + "px", "margin": "-21px -20px", "marginBottom": "20px", "zIndex": "10", "backgroundColor": "#fff" }}>
                <div className="float-flex" style={{ "alignItems": "center", "padding": "10px 20px" }}>
                    <strong style={{ "fontSize": "18px" }}><small>Order Summary: {"$" + session.cart.summary.total}</small> </strong>
                    <div style={{ "display": "block", "fontSize": "12px" }}>{title_items}</div>
                </div>
            </div>

        )
    }


    if (!session?.cart?.items?.length) {
        history.push("/checkout/cart");
        return (<>No items...</>)
    }

    const getPickupText = () => {

        let _location_name = "";
        if (window.sitesettings.store_info.is_multistore && window.sitesettings.store_info.multistore_info.length > 0) {
            let _s = false;
            if (session.location_id) {
                _s = window.sitesettings.store_info.multistore_info.find(item => item.id == session.location_id);
            } else {

                _s = window.sitesettings.store_info.multistore_info[0];
            }
            _location_name = _s?.name;
            return `Pick up at: ${_location_name}`;
        } else {
            return "Pick up in store";
        }
    }


    const drawLabel = () => {


        if (state.delivery_method === "electronic") {
            return "Email";
        }

        if (state.delivery_method === "ship" || state.delivery_method === "local_delivery") {

            if (state.custom && window.sitesettings.checkout_info.label_override_ship) {
                return window.sitesettings.checkout_info.label_custom_ship;
            }
            return "Ship";
        }

        if (state.delivery_method === "pickup") {
            if (state.custom === true && window.sitesettings.checkout_info.label_override_pickup) {
                return window.sitesettings.checkout_info.label_custom_pickup;
            }
            return getPickupText()
        }

        return ""

    }



    return (

        <Spin indicator={<></>} spinning={submitLoading}>
            <div className="container" style={{ "padding": "0px", "backgroundColor": "transparent" }}>
                <Form form={form}  layout="vertical" onValuesChange={() => validateForm()} onFinish={(_f) => submit(_f)}>

                    <Row gutter={0}>
                        <Col sm={24} lg={15} style={{ "backgroundColor": "#fff" }} >

                            <div style={{ "padding": "20px" }}>
                                <Typography.Title style={{ "marginBottom": "20px" }} level={2}><nobr>Checkout</nobr><div style={{ "fontSize": "16px" }}><em style={{ "opacity": "0.8" }}>Delivery:</em> <strong>{drawLabel()}</strong></div></Typography.Title>

                                {drawMobileSummary()}
                                {/* SHIPPING */}
                                {((state.delivery_method === "ship" || state.delivery_method === "local_delivery") && <div>
                                    {drawBillingShipping()}

                                    <div className="show">
                                        <Divider orientation="left" ><big><strong>Promo Codes</strong></big></Divider>

                                        <PromoCodes /></div>
                                    <div className="shim" />
                                    <Divider orientation="left" ><big><strong>Select a Shipping Option</strong></big></Divider>
                                    {((state.delivery_method === "ship" || state.delivery_method === "local_delivery") &&
                                        <>
                                            <Form.Item style={{ "height": "0px", "overflow": "hidden" }} rules={[{ message: 'Shipping Required' }]} name="shipping_provider">

                                                <Input tabIndex={-1} disabled aria-hidden="true" />
                                            </Form.Item>
                                            <Form.Item style={{ "height": "0px", "overflow": "hidden" }} rules={[{ required: true, message: 'Shipping Label' }]} name="shipping_label">

                                                <Input tabIndex={-1} disabled aria-hidden="true" />
                                            </Form.Item>
                                        </>
                                    )}
                                    {drawShipping()}
                                    {errorMSG}

                                    <Form.Item hidden name="multiship_method"><Input /></Form.Item>
                                    <div className="shim" />
                                </div>)}


                                {drawNotice()}
                                <div className="shim" />

                                <Divider orientation="left" ><big><strong>Additional Information</strong></big></Divider>
                                {drawContact()}
                                <div className="shim" />
                                <div><GiftCardPurchase /></div>
                                {drawPayment()}
                                <div id="end"></div>
                            </div>
                        </Col>
                        <Col span={24} lg={9} className="checkout-summary-bg" >

                            <div className="hide">
                                <div className="float-flex" style={{ "padding": "0px 20px" }}>
                                    <div>&nbsp;</div>
                                    <div>
                                        <Button style={{ "position": "relative", "bottom": "-10px" }} onClick={() => history.push("/checkout/cart")}>Go back</Button>
                                    </div>
                                </div>
                            </div>


                            {drawSummary()}

                        </Col>
                    </Row>
                </Form>
            </div>
        </Spin>
    )
}