import React from "react";
import { getColor } from "../util/CC";
import Html from "../components/utilities/Html";
import { Button } from "antd";
import { useMediaQuery } from "react-responsive";
import { hasLink } from "../util/Utils";

export default function ImageGrid(props) {

    const template = window.sitesettings.template_info;
    const ismobile = useMediaQuery({ query: '(max-width: 716px)' })
    const { content = {} } = props;

    const {
        location = "main",
        component = "blank_space",
        container = true,
        data = {},
        injected_data = {},
    } = content;

    const {
        images = []
    } = injected_data;

    const {
        width = 400,
        height = 300,
        padding_y = 0,
        padding_x = 0,
        horizontal_align = "center",
        vertical_align = "center",
        size = "cover",
        gap_y = 10,
        gap_x = 10,
        justify = "center",
        background_colour = 0,
        image_radius = 0,

        headline_preset = 0,
        button_preset = 0,
        paragraph_preset = 0,
        align = "center",
        headline_position = "bottom",
        paragraph_position = "bottom",
        button_position = "bottom",
        reverse_wrap = false,
        mobile_image_height = 100,
        allow_mobile_overrides = false,

    } = data;

    const parsePath = (url) => {
        const abs = /^(?:[a-z]+:)?\/\//i;
        return (abs.test(url)) ? url : "https://cdn1.bookmanager.com/i/" + window.san + url;
    }

   

    const drawImage = (_image, _index) => {

        let _coverStyle = {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: (_image.link) ? "pointer" : "default",
            objectFit: "cover",
            height: (allow_mobile_overrides && ismobile) ? mobile_image_height + "px" : height + "px",
            width: (ismobile) ? "100%" : width + "px",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url('${parsePath(_image.path)}')`,
            backgroundSize: size,
            backgroundPosition: vertical_align + " " + horizontal_align,
            borderRadius: image_radius + "px"
        }

        const drawText = (_position = "bottom", _image) => {
            return (
                <>
                    {(headline_position === _position && _image.headline &&
                        <h2 style={{ "display": "block" }} className={"bm-txt-" + headline_preset} >
                            {_image.headline}
                        </h2>
                    )}
                    {(paragraph_position === _position && _image.paragraph &&
                        <div className={"bm-component  bm-txt-" + paragraph_preset} >
                            <Html html={_image.paragraph} />
                        </div>
                    )}
                    {(button_position === _position && _image.button_label &&
                        <div style={{ "display": "block" }}>
                            <div className="shim" /><div className="shim" />
                            <Button aria-label={(_image.tab) ? "Visit link (open in new tab)" : "Visit link"} onClick={() => linkOut(_image)} className={"bm-btn-" + button_preset} >{_image.button_label}</Button>
                        </div>
                    )}
                </>
            )
        }

        

        return (
            <div>
                <div style={{ "width": (ismobile) ? "100%" : width + "px", "textAlign": align }}>
                    {drawText("top", _image)}
                </div>
                <div tabIndex={(hasLink(_image.link) ? 0 : null)} aria-label={(!hasLink(_image.link)) ?_image.alt : (_image.tab) ? "Visit link (opens in a new tab)" : "Visit link"} className={(_image.link) ? "bm-img-link" : ""} title={_image.alt} onKeyDown={(e) => {if(e.key === "Enter"){linkOut(_image)}}} onClick={() => linkOut(_image)} style={_coverStyle} key={_index}>
                    <div style={{ "width": (ismobile) ? "100%" : width + "px", "textAlign": align }}>
                        {drawText("middle", _image)}
                    </div>
                </div>
                <div style={{ "width": (ismobile) ? "100%" : width + "px", "textAlign": align }}>
                    {drawText("bottom", _image)}
                </div>
            </div>
        )
    }


   

    const linkOut = (_image) => {
        if (hasLink(_image.link)) {
            window.open(_image.link, (_image.tab) ? "_blank" : "_self");
        }
    }


    const drawRender = () => {

        const gridStyle = {
            background: getColor(background_colour, template),
            display: 'flex',
            flexWrap: (reverse_wrap) ? "wrap-reverse" : "wrap",
            flexDirection : (ismobile) ? "column" : "row",
            justifyContent: justify,
            gap: `${gap_y}px ${gap_x}px`,
            padding: `${padding_y}px ${padding_x}px`,
        };

        return (
            <div className={(container) ? "preview-container" : ""}>
                <div style={gridStyle}>
                    {images.map((item, index) => {
                        return drawImage(item, index);
                    })}
                </div>
            </div>
        )
    }


    return drawRender();
}